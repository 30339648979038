import request from "@/utils/request";

export function getGameList(params) {
  return request({
    url: "/game_list/",
    method: "get",
    params,
  });
}

export function getGameData(params) {
  return request({
    url: "/game_data/",
    method: "get",
    params,
  });
}

export function getGameAdvanceData(params) {
  return request({
    url: "/game_advance_data/",
    method: "get",
    params,
  });
}

export function getTeamGameData(params) {
  return request({
    url: "/team_game_data/",
    method: "get",
    params,
  });
}

export function getPlayerGameData(params) {
  return request({
    url: "/player_game_data/",
    method: "get",
    params,
  });
}

export function getEventByStat(params) {
  return request({
    url: "/get_event_by_stat/",
    method: "get",
    params,
  });
}

export function getEventByPosition(params) {
  return request({
    url: "/get_event_by_position/",
    method: "get",
    params,
  });
}
